import React, { Component } from 'react';

import Login from './Login'

export default class LoginContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            shouldIframeBeShown: false,
            iframeLoaded: false,
        };
    }

    componentDidMount() {
        this.props.ai.trackPageView("login");
    }

    iframeLoaded = () => {
        this.setState({
            iframeLoaded: true
        });

    }

    login = () => {
        this.setState({
            isLoading: true,
            shouldIframeBeShown: true
        });
      }

    render() {
        const display = this.state.shouldIframeBeShown && this.state.iframeLoaded ;

        const loginProps = {
            login: this.login,
            isLoading: this.state.isLoading,
            onChangeLanguage: this.props.onChangeLanguage,
            activeLanguage: this.props.activeLanguage
        }
        return (
            <div className="container">
                {!display ? (
                    <React.Fragment>
                        <Login {...loginProps}/>
                    </React.Fragment>
                            )
                : null}
                <iframe
                    style={{display: (display ? "block" : "none")}}
                    title="contentFrame"
                    src="https://appsimmix.msiberia.net/dataforce/login"
                    width="100%"
                    height="700"
                    onLoad={() => this.iframeLoaded()}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                />
            </div>
        );
    }
}