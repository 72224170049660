import 'react-app-polyfill/ie9';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { authContext } from './libs/adal'
import ReactAI from 'react-appinsights';
import config from './config'

ReactAI.init({instrumentationKey: config.APP_CONFIG.aiInstrumentationKey, debug: true});

authContext.handleWindowCallback();
//prevent iframe double app
if (window === window.parent) {
  if (!authContext.isCallback(window.location.hash)) {
    ReactDOM.render(
        <App />,
      document.getElementById("root")
    );
  }
}