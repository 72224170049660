import React, { Component } from 'react';
import translations from '../../../translations.json';
import links from '../../../links.json';
import logo from '../../../assets/img/logo.svg';
import background from '../../../assets/img/main-big.jpg';
import './Login.css';

import LoaderButton from '../../shared/LoaderButton'

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenLangPicker: false,
        };
    }

    onClickLangPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenLangPicker) this.setState({ isOpenLangPicker: !!value });
        this.setState(({ isOpenLangPicker }) => ({ isOpenLangPicker: !isOpenLangPicker }));
    }


    render() {
        const { activeLanguage, isLoading, login, onChangeLanguage } = this.props;
        const { isOpenLangPicker } = this.state;
        const { onClickLangPicker } = this;
        return (
            <div className="login">
                <img className="login__background" src={background} alt="beautifull city" />
                <div className="login__container">
                    <header className="login__header">
                            <div className="login__logo-wrapper">
                                <img className="login__logo-img" src={logo} alt="IRIS NET" />
                            </div>
                    </header>
                    <div className="login__content">
                        <h2 className="login__greeting">
                            {translations[activeLanguage].values['welcome_1']} <span className="login__greeting_bold">DataForce</span>, {translations[activeLanguage].values['welcome_2']}
                        </h2>
                        <div className="login__button">
                            <LoaderButton
                                onClick={() => login()}
                                isLoading={isLoading}
                                text={translations[activeLanguage].values['login']}
                                loadingText={"Loading..."}
                            />
                        </div>
                        <div >
                            <LoaderButton
                                onClick={() => window.open(translations[activeLanguage].values['product_video_url'], '_blank' ).focus()}
                                text={translations[activeLanguage].values['product_video']}
                            />
                        </div>
                    </div>
                    <footer className="login__footer">
                        <div className="login__footer-left-side">
                            <a href={links[activeLanguage].values['imprint']} className="login__gray_item">{translations[activeLanguage].values['imprint']}</a>
                            <a href={links[activeLanguage].values['terms']} className="login__gray_item">{translations[activeLanguage].values['terms']}</a>
                            <a href={links[activeLanguage].values['privacy']} className="login__gray_item">{translations[activeLanguage].values['privacy']}</a>
                        </div>
                        <div className="login__footer-right-side">
                            <p className="login__gray_item">{translations[activeLanguage].values['language']}: </p>
                            <div onClick={onClickLangPicker} className="login__cursor-pointer">
                                <div className="login__language-container">
                                    <div onClick={() => onChangeLanguage(activeLanguage)} className="login__language">{translations[activeLanguage].name}</div>
                                    {
                                        translations.map(({ name }) => name).reduce((accum, lang, idx) => idx !== activeLanguage ? [...accum, { lang, idx }] : accum, [])
                                            .map(({ lang, idx }, index) => (
                                                <div
                                                    key={lang}
                                                    className={isOpenLangPicker ? "login__language-other_active" : "login__language-other"}
                                                    style={{ top: `${isOpenLangPicker ? -10 * ++index : 0}px` }}
                                                    onClick={() => onChangeLanguage(idx)}
                                                >
                                                    {lang}
                                                </div>
                                            ))
                                    }
                                </div>
                                <div className={isOpenLangPicker ? "login__lang-picker-triangle_active" : "login__lang-picker-triangle"}></div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}